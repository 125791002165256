<template>
  <div>
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-list dense nav>
        <v-subheader class="text-uppercase font-weight-bold">Menu</v-subheader>
        <v-btn
          v-if="canUploadBanners"
          to="/admin/banners"
          text
          class="mx-1"
        >
          Manage Banners
        </v-btn>

        <v-btn
          v-if="canUploadBulletin"
          to="/admin/bulletin"
          text
          class="mx-1"
        >
          Manage Bulletin
        </v-btn>

      </v-list>

      <template v-slot:append>
        <div class="pa-2">
          <v-btn v-show="!isAuthenticated" color="primary" large @click="signIn()">
            Log in
          </v-btn>
        
          <v-btn v-show="isAuthenticated" color="primary" large @click="signOut()">
            Log out
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-btn
      class="d-md-none drawer-button"
      rounded
      @click="drawer = !drawer"
    >
      <v-icon right>mdi-menu</v-icon>
    </v-btn>

    <v-app-bar app height="80">
      <v-container class="py-0 px-0 px-sm-2 fill-height" :fluid="isFluid">
        <router-link v-slot="{ navigate }" to="/" class="d-flex align-center text-decoration-none mr-2">
          <span class="font-weight-black text-uppercase" role="link" @click="navigate" @keypress.enter="navigate">
            Sudbury Adventist Church
          </span>
        </router-link>

        <div class="d-none d-md-block">
          <v-btn
            v-if="canUploadBanners"
            to="/admin/banners"
            text
            class="mx-1"
          >
            Manage Banners
          </v-btn>

          <v-btn
            v-if="canUploadBulletin"
            to="/admin/bulletin"
            text
            class="mx-1"
          >
            Manage Bulletin
          </v-btn>
        </div>

        <v-spacer></v-spacer>

        <v-btn v-show="!isAuthenticated" color="primary" large @click="signIn()">
          Log in
        </v-btn>
        
        <v-btn v-show="isAuthenticated" color="primary" large @click="signOut()">
          Log out
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    isFluid: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      drawer: null
    }
  },
  computed: {
    ...mapGetters('app', {
      isAuthenticated: 'isAuthenticated',
      canUploadBanners: 'canUploadBanners',
      canUploadBulletin: 'canUploadBulletin'
    })
  },
  async mounted() {      
    const userDetails = await this.$msal.getUserDetails()

    this.$store.dispatch('app/setUserDetails', userDetails)
  },
  methods: {
    async signIn() {
      await this.$msal.signIn()
      
      const userDetails = await this.$msal.getUserDetails()

      this.$store.dispatch('app/setUserDetails', userDetails)
    },
    async signOut() {
      await this.$msal.signOut()
      this.$store.dispatch('app/setUserDetails', null)
    }
  }
}

</script>

<style scoped>
.drawer-button {
  position: fixed;
  top: 64px;
  left: -22px;
  z-index: 6;
}
</style>
